import React from "react"
import Layout from "../../components/Layout/LayoutUSTour"
import PageHeader from "../../components/shared/PageHeader"
import CourthouseAnimation from "../../components/Animations/CourthouseAnimation";
import CreativeUSTour from "../../components/us-tour/CreativeUsTour";
import CastBlock from "../../components/us-tour/CastAndCreativePage/CastBlock";

const CastPage = () => (
	<Layout
		title="Cast & Creative | North American Tour | Back to the Future the Musical"
		description="Cast & Creative | The official website for Back to the Future The Musical - North American Tour. Landing in a city near you from Summer 2024. "
		booking="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web"
	>
		<PageHeader city="us-tour" title="Cast & Creative" />
		<CastBlock city="us-tour"/>
		{/* <CastBlock city="us-tour"/> */}
		{/* <ImageBlock/> */}
		{/* <p className="text-center my-3">(London Cast)</p> */}
		<CourthouseAnimation/>
		<CreativeUSTour/>
	</Layout>
)

export default CastPage